import { Box, Container, IconButton, Link, Stack, Typography, styled, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import logoSvg from '../../assets/logos/logo-reverse-tag.svg'
import { Link as RouterLink } from 'react-router-dom';
import paths from '../../router/paths';
import { Facebook, Google, LinkedIn, Twitter } from '@mui/icons-material';

/* ---------------------------- Styled Components --------------------------- */

const Logo = styled('img')``

export default function Footer({ details }) {
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <Container
            maxWidth
            sx={{
                backgroundColor: theme => theme.palette.background.primary.main,
                color: theme => theme.palette.background.primary.contrastText
            }}
        >
            <Container maxWidth='lg' sx={{ pt: 3 }}>
                <Stack direction='row' justifyContent={isLg ? 'space-between' : 'center'} alignItems='center'>
                    {isLg &&
                        <Box>
                            <Stack direction='column' justifyContent='end' spacing={1}>
                                {paths.length > 0 && paths.map(obj =>
                                    <Link
                                        component={RouterLink}
                                        to={obj.path}
                                        sx={{
                                            color: theme => theme.palette.background.primary.contrastText,
                                            textDecoration: 'none',
                                            '&:hover': {
                                                textDecoration: 'underline',
                                                textDecorationColor: theme => theme.palette.background.primary.contrastText,
                                                textUnderlineOffset: 6
                                            }
                                        }}
                                    >
                                        <Typography fontSize={14} fontWeight={500}>
                                            {obj.title}
                                        </Typography>
                                    </Link>
                                )}
                            </Stack>
                        </Box>
                    }
                    <Box>
                        <RouterLink to='/'>
                            <Logo src={logoSvg} sx={{ maxHeight: 125, minWidth: 250, objectFit: 'contain', objectPosition: 'center' }} />
                        </RouterLink>
                        {/* <Stack direction='row' spacing={1} justifyContent='space-between' py={2}>
                            <IconButton>
                                <Facebook />
                            </IconButton>
                            <IconButton>
                                <LinkedIn />
                            </IconButton>
                            <IconButton>
                                <Google />
                            </IconButton>
                            <IconButton>
                                <Twitter />
                            </IconButton>
                        </Stack> */}
                    </Box>
                </Stack>
                <Typography fontSize={12} textAlign='center' pt={4} pb={2}>
                    © Copyright {new Date().getFullYear()} Vicinia Fiber. All rights reserved.
                </Typography>
            </Container>
        </Container>
    )
}

Footer.propTypes = {
    details: PropTypes.object
}