import { AppBar, Box, Divider, Drawer, IconButton, ListItemButton, ListItemText, Toolbar, styled, useScrollTrigger } from '@mui/material';
import PropTypes from 'prop-types';
import logoSvg from '../../assets/logos/logo-reverse-tag.svg'
import paths from '../../router/paths';
import { cloneElement, useState } from 'react';
import { Menu } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';

/* ---------------------------- Styled Components --------------------------- */

const Logo = styled('img')``

function ElevationScroll({ children, window }) {
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function HeaderMobile({ details }) {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <ElevationScroll>
                <AppBar sx={{ backgroundColor: (theme) => theme.palette.background.primary.main }}>
                    <Toolbar sx={{ backgroundColor: (theme) => theme.palette.background.primary.main }}>
                        <Link to='/'>
                            <Logo src={logoSvg} sx={{ maxHeight: 35, minHeight: 35, objectFit: 'contain', objectPosition: 'center' }} />
                        </Link>
                        <IconButton onClick={() => { setDrawerOpen(true) }} sx={{ marginLeft: 'auto' }}>
                            <Menu sx={{ color: theme => theme.palette.background.primary.contrastText }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar />
            <Drawer
                anchor='left'
                open={drawerOpen}
                onClose={() => {
                    setDrawerOpen(false)
                }}
                PaperProps={{
                    sx: {
                        backgroundColor: (theme) => theme.palette.background.primary.main,
                        color: theme => theme.palette.background.primary.contrastText
                    }
                }}
            >
                <Link to='/' onClick={() => setDrawerOpen(false)}>
                    <Box pt={3}>
                        <center>
                            <Logo src={logoSvg} sx={{ maxHeight: 40, minHeight: 40, objectFit: 'contain', objectPosition: 'center' }} />
                        </center>
                    </Box>
                </Link>
                <Divider sx={{ backgroundColor: theme => theme.palette.grey[700], my: 2 }} />
                <Box sx={{ width: 250 }}>

                    {paths?.map((page, index) => (
                        <>

                            {/* <ListItemButton onClick={() => (menuExpanded === index) ? setMenuExpanded(null) : setMenuExpanded(index)}>
                                                <ListItemText primary={page.title} />
                                                {menuExpanded === index ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={menuExpanded === index} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {
                                                        page.children.map(childPage =>
                                                            <ListItemButton onClick={() => { setDrawerOpen(false); navigate(`/${childPage.id.toLocaleLowerCase()}`); }} sx={{ pl: 3 }}>
                                                                <ListItemIcon sx={{ minWidth: 'auto', mr: 1 }}>
                                                                    •
                                                                </ListItemIcon>
                                                                <ListItemText primary={childPage.title} />
                                                            </ListItemButton>
                                                        )
                                                    }
                                                </List>
                                            </Collapse>
                                        </> */}
                            <ListItemButton onClick={() => {navigate(page.path); setDrawerOpen(false)}}>
                                <ListItemText primary={page.title} />
                            </ListItemButton>

                        </>
                    ))}

                </Box>
            </Drawer>
        </>
    )
}

HeaderMobile.propTypes = {
    details: PropTypes.object,
}