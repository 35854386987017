import { CircularProgress } from '@mui/material';
import { Container } from '@mui/system';
import PropTypes from 'prop-types';

export default function Loading({ details }) {

    return (
        <Container maxWidth>
            <CircularProgress />
        </Container>
    )
}

Loading.propTypes = {
    details: PropTypes.object
}