import { Box, Container, Link, Stack, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';
import logoSvg from '../../assets/logos/logo-reverse-tag.svg'
import { Link as RouterLink } from 'react-router-dom';
import paths from '../../router/paths';

/* ---------------------------- Styled Components --------------------------- */

const Logo = styled('img')``

export default function Header({ details }) {

    return (
        <Container
            maxWidth
            sx={{
                backgroundColor: theme => theme.palette.background.primary.main,
                color: theme => theme.palette.background.primary.contrastText,
            }}
        >
            <Container maxWidth='lg' sx={{ py: 3 }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <RouterLink to='/'>
                        <Logo src={logoSvg} sx={{ maxHeight: 125, minHeight: 50 }} />
                    </RouterLink>
                    <Box>
                        <Stack direction='row' justifyContent='end' spacing={3}>
                            {paths.length > 0 && paths.map(obj =>
                                <Link
                                    component={RouterLink}
                                    to={obj.path}
                                    sx={{
                                        color: theme => theme.palette.background.primary.contrastText,
                                        textDecoration: 'none',
                                        '&:hover': {
                                            textDecoration: 'underline',
                                            textDecorationColor: theme => theme.palette.background.primary.contrastText,
                                            textUnderlineOffset: 6
                                        }
                                    }}
                                >
                                    <Typography fontSize={16} fontWeight={500}>
                                        {obj.title}
                                    </Typography>
                                </Link>
                            )}
                        </Stack>
                    </Box>
                </Stack>
            </Container>
        </Container>
    )
}

Header.propTypes = {
    details: PropTypes.object,
}