import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from "@mui/material";
import Header from './components/header';
import Footer from './components/footer';
import { Outlet, useLocation } from 'react-router-dom';
import HeaderMobile from './components/headerMobile';
import { Suspense, useEffect } from 'react';
import Loading from './components/loading';

export default function Main({ children }) {
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const location = useLocation();

    // Scroll to top on route change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <Grid container direction='column' sx={{ minHeight: '100vh' }}>
            <Grid item xs={12} sx={{ display: 'flex' }}>
                {isLg ? <Header /> : <HeaderMobile />}
            </Grid>
            <Grid item xs style={{ display: 'flex', flex: 1 }}> {/* This line ensures that the grid item takes up remaining space so that the footer will always be at the bottom with no weird blank space */}
                <Suspense fallback={<Loading />}>
                    <Outlet />
                </Suspense>
            </Grid>
            <Grid item xs={12}>
                <Footer />
            </Grid>
        </Grid>
    )
}

Main.propTypes = {
    children: PropTypes.node
}