import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Router from "./router/router";

function App() {
  const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: "#4461ad",
        contrastText: "#FFFFFF",
      },
      secondary: {
        main: "#c457a3",
        contrastText: "#FFFFFF"
      },
      bodyText: '#000044',
      background: {
        primary: {
          main: "#010101",
          contrastText: "#FFFFFF"
        },
        secondary: {
          main: "#4461ad",
          contrastText: "#FFFFFF"
        }
      },
    },
    components: {
      MuiIconButton: {
        defaultProps: {
          sx: {
            backgroundColor: theme => theme.palette.primary.main,
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: theme => theme.palette.primary.dark
            }
          },
        },
      },
      MuiButton: {
        variants: [
          {
            props: { size: 'large'},
            style: {
              fontSize: 20,
              paddingLeft: 30,
              paddingRight: 30,
              paddingTop: 12,
              paddingBottom: 12
            }
          }
        ]
      }
    }
  })


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}

export default App;
