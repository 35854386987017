
const paths = [
    {
        title: 'Home',
        path: '/'
    },
    {
        title: 'Our Technology',
        path: '/our-technology'
    },
    {
        title: 'Property Owners/Developers',
        path: '/property-owners-and-developers'
    },
    {
        title: 'Value Proposition',
        path: '/bandwidth-and-wifi'
    },
    {
        title: 'Contact Us',
        path: '/contact-us'
    },
]

export default paths;