import { lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Main from "../layouts/main"
const Home = lazy(() => import('../pages/home'));
const Technology = lazy(() => import('../pages/developers'));
const Developers = lazy(() => import('../pages/technology'));
const Bandwidth = lazy(() => import('../pages/bandwidth'));
const Contact = lazy(() => import('../pages/contact'));

export default function Router({children}) {

    // Routes
    const routes = createBrowserRouter([
        {
            path: '/',
            element: <Main />,
            children: [
                {
                    index: true,
                    element: <Home />
                },
                {
                    index: true,
                    path: 'our-technology',
                    element: <Technology />
                },
                {
                    index: true,
                    path: 'property-owners-and-developers',
                    element: <Developers />
                },
                {
                    index: true,
                    path: 'bandwidth-and-wifi',
                    element: <Bandwidth />
                },
                {
                    index: true,
                    path: 'contact-us',
                    element: <Contact />
                },
            ]
        },
    ])

    return (
        <RouterProvider router={routes}>
            {children}
        </RouterProvider>
    )
}